import type {EventPayload} from '../behaviors/octolytics-tracking'
import {sendOctoEvent} from '../behaviors/octolytics-tracking'

type EventName = 'menu-activation' | 'menu-deactivation' | 'click' | 'query' | 'search'

let currentEventPayload: EventPayload = {}

export function trackSelection(anchor: Element): void {
  const targetType = anchor.getAttribute('data-target-type')!

  if (targetType === 'Search') {
    const form = document.querySelector<HTMLFormElement>('.js-site-search-form')!
    const scopeType = form.getAttribute('data-scope-type')
    const itemType = anchor.getAttribute('data-item-type')

    if (scopeType) {
      updateCurrentEventPayload({
        dimensions: {
          scope_id: parseInt(form.getAttribute('data-scope-id') || ''),
          scope_type: scopeType,
          target_scope: itemType || ''
        }
      })
    }
    trackJumpToEvent('search')
  } else if (targetType === 'Project' || targetType === 'Repository' || targetType === 'Team') {
    updateCurrentEventPayload({
      dimensions: {
        target_id: parseInt(anchor.getAttribute('data-target-id') || ''),
        target_type: targetType,
        target_scope: ''
      },
      measures: {
        client_rank: parseInt(anchor.getAttribute('data-client-rank') || ''),
        server_rank: parseInt(anchor.getAttribute('data-server-rank') || '')
      }
    })
    trackJumpToEvent('click')
  }
}
export function trackJumpToEvent(eventName: EventName): boolean {
  const actorId = parseInt(
    document.head?.querySelector<HTMLMetaElement>('meta[name="octolytics-actor-id"]')?.content || ''
  )
  if (!actorId) {
    // Do not track events for anonymous users.
    return false
  }
  currentEventPayload.dimensions = currentEventPayload.dimensions || {}
  currentEventPayload.dimensions.actor_id = actorId

  let sessionId = currentEventPayload.dimensions && currentEventPayload.dimensions['session_id']

  if (eventName === 'menu-activation' && sessionId) {
    // Do not track duplicate menu activation for this session.
    return false
  }

  if (eventName !== 'menu-activation' && !sessionId) {
    // Do not track any duplicate events after session has ended.
    return false
  }

  if (eventName === 'menu-activation') {
    // Start a new session.
    sessionId = uuidv4()
    updateCurrentEventPayload({dimensions: {session_id: sessionId}})
  }

  currentEventPayload['event_type'] = `jump-to-${eventName}`
  if (!sessionId) return false
  trackEvent(currentEventPayload)

  if (eventName === 'menu-deactivation' || eventName === 'click' || eventName === 'search') {
    // Reset payload after any event that concludes the session.
    resetCurrentEventPayload()
  }

  return true
}

export function updateCurrentEventPayload(attributes: EventPayload): void {
  if (attributes.context) {
    currentEventPayload.context = Object.assign(currentEventPayload.context || {}, attributes.context)

    // Shove context values into the dimensions container in an attempt to fix https://git.io/vhJF9
    currentEventPayload.dimensions = Object.assign(currentEventPayload.dimensions || {}, attributes.context)
  }
  if (attributes.dimensions) {
    currentEventPayload.dimensions = Object.assign(currentEventPayload.dimensions || {}, attributes.dimensions)
  }
  if (attributes.measures) {
    currentEventPayload.measures = Object.assign(currentEventPayload.measures || {}, attributes.measures)
  }
}

export function resetCurrentEventPayload(): void {
  currentEventPayload = {}
}

export function cloneCurrentEventPayload(): EventPayload {
  // First shallow clone the whole object.
  const result = Object.assign({}, currentEventPayload)

  // Then clone any instance of `display_set` (the only nested object) separately.

  if (result.context && result.context.display_set) {
    result.context.display_set = [...result.context.display_set]
  }
  if (result.dimensions && result.dimensions.display_set) {
    result.dimensions.display_set = [...result.dimensions.display_set]
  }

  return result
}

function trackEvent(payload: EventPayload): boolean {
  sendOctoEvent(payload)
  return true
}

// https://stackoverflow.com/a/2117523
function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
